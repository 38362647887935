<template>
  <div class="home fixed" :style="`background-image: url(${staticUrl}index_bg.png)`">

    <div class="home-bg-opacity"></div>

    <div class="relative-position" style="top: -80%">
      <!-- 选择语言对话框 -->
      <div class="set-language-dialog absolute full-width" :class="isLanguage ? 'z-index-1' : ''">
        <div v-if="isLanguage" class="set-language relative-position column justify-center border-radius-10">
          <div class="cancel absolute guanIcon" @click="onLanguage(1)"><q-img class="width-40"
              src="../assets/image/guan.png" alt="" /></div>
          <div class="text-center izbol font-34 e-mb-30">تىل تاللاڭ</div>
          <div class="language row izbol rtl justify-around text-white">
            <div class="uy-btn width-130 e-py-10 text-center" @click="onLanguage(1)">ئۇيغۇرچە</div>
            <div class="zh-btn width-130 e-py-10 text-center" @click="onLanguage(2)">中午</div>
          </div>
        </div>
      </div>

      <div class="bg-positive width-110-p height-120 oztil text-center relative-position overflow-hidden"
        style="border-radius: 120px 0 0 120px;" @click="onCategory(1)">
        <div class="absolute width-110-p z-index-1 izbol text-white line-height-120 font-18" style="right: 50px;">ئۆزتل
          كىنو</div>
        <div class="height-120">
          <q-img class="full-height width-110-p relative-position right-10-p" src="../assets/image/oztil-demo.png"
            alt="" />
        </div>
      </div>
      <div class="bg-positive width-110-p height-120 hojjatlik text-center e-mt-50 relative-position overflow-hidden"
        style="border-radius: 0 120px 120px 0; left: -10%;" @click="onCategory(2)">
        <div class="absolute width-110-p z-index-1 izbol text-white line-height-120 font-18" style="left: 50px;">
          ھۆججەتلىك فىلىم</div>
        <div class="height-120">
          <q-img class="full-height width-110-p relative-position" src="../assets/image/hojjatlik-demo.png" alt="" />
        </div>
      </div>

    </div>

    <div class="text-color-grey-1 bg-white izbol text-center font-12 relative-position e-pt-30 e-pb-50"
      style="bottom: 65%;">
      <q-img src="../assets/image/home-logo.png" style="width: 145px; height: 50px;" alt="" />
      <div class="e-mt-30">ئۆمۈرلۈك ئۆگەنگىچىلەرگە نىسبەتەن ھۆججەتلىك فىلىم</div>
      <div>بىلىم ئېلىشتىكى قىممەتلىك بايلىق، ئىلھام ھەم</div>
      <div>ھەركەتلەندۈرگۈچ كۈچ !</div>
      <div></div>
    </div>

    <!-- <div class="column content-center izbol full-height home-bg-opacity"></div> -->

  </div>
</template>

<script>
import { isCode, reqLoginFun } from '@/assets/js/authService.js';

export default {
  name: 'HomeView',
  components: {},
  data () {
    return {
      staticUrl: 'https://bayan.adaxtv.com/static/image/',
      langyageActive: 1, // 1: 维语、2：汉语
      isLanguage: true,
    }
  },
  created () {
    this.setLanguage();
    if (!isCode(this)) reqLoginFun(); // 判断是否登录
  },
  methods: {
    // 选择语言
    onLanguage (lang) {
      localStorage.setItem('language', lang);
      this.langyageActive = lang;
      if (lang == 1) {
        this.$i18n.locale = 'ug'
      } else {
        this.$i18n.locale = 'zh'
      }
      this.isLanguage = !this.isLanguage;
    },

    // 默认不选语言设置
    setLanguage () {
      localStorage.setItem('language', 1);
    },

    // 选择类型
    onCategory(type) {
      switch (type) {
        case 1:
          this.$router.push({ name: 'originalFilm' })
          break;
        case 2:
          this.$router.push({ name: 'documentaryFilm' })
          break;
        default:
          break;
      }
    },
  }
}
</script>

<style scoped>
.home {
  height: 100vh;
  width: 100%;
  background-size: 100% auto;
  background-repeat: repeat-y;
  /* 图片在垂直方向重复 */
  background-position: 0 100%;
  animation: scrollBackground 4s linear infinite;
}
@keyframes scrollBackground {
  0% {
    background-position: 0 100%;
  }

  100% {
    background-position: 0 0;
    /* 图片向上移动一个图片高度的距离 */
  }
}
.home-bg-opacity {
  /* width: 100vw; */
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
}
.category-imgBg-1 { background-color: #363e4d; }
.category-imgBg-2 { background-color: #1e2630; }
.category-imgBg-3 { background-color: #191f2c; }
.set-language-dialog { padding: 50px; }
.set-language { width:100%; height: 230px; background-color: rgba(255, 255, 255, 0.9); }
.uy-btn { background: linear-gradient(to right,#4eac7d, #9fecbf); border-radius: 20px 0; }
.zh-btn { background: linear-gradient(to right,#ef8b34, #f7d56f); border-radius: 0 20px;}
.guanIcon { top: -20px; left: -10px; }
.oztil { transform: rotate(-10deg); }
.hojjatlik { transform: rotate(-10deg); }
</style>
