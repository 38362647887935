import { get, post, deleteMethod, patch } from "./http.js";

// 获取纪录片
export const getDocumentary = (documentaryType, pageSize, pageNo) => {
  let url = `/documentary?documentaryType=${documentaryType}&pageSize=${pageSize}&pageNo=${pageNo}`;
  return get(url);
};

// 获取
export const getOriginalmovie = (play_year, pageSize, pageNo, countryId, keyWord) => {
  let url = `/originalmovie?play_year=${play_year}&pageSize=${pageSize}&pageNo=${pageNo}&countryId=${countryId}&keyWord=${keyWord}`;
  return get(url);
};

// 获取用户
export const getuserInfo = (params) => {
  let url = "/getuserInfo";
  return post(url, params);
};

// 随机获取电影
export const getRandomMovie = (pageSize, recommend, categoryId) => {
  let url = `/randomGetMovie?pageSize=${pageSize}&recommend=${recommend}&categoryId=${categoryId}`;
  return get(url);
};

// 获取用户
export const reqLogin = (params) => {
  let url = "/getCode";
  return get(url, params);
};

// 获取价格
export const getTargetPrices = () => {
  let url = "/targetPrices";
  return get(url);
};

// 获取价格
export const getMovieDetail = (postId) => {
  let url = `/house/${postId}`;
  return get(url);
};

// 统一下单
export const payment = (params) => {
  let url = "/payments";
  return post(url, params);
};

// 制作分享图
export const getGeneratePicture = (params) => {
  let url = '/generatePicture';
  return post(url, params);
};

// 收藏
export const setFavorites = (params) => {
  let url = '/favorites';
  return post(url, params);
};

// 收藏
export const getFavorites = (params) => {
  let url = '/getFavorites';
  return post(url, params);
};

// 投诉建议
export const setComplaint = (params) => {
  let url = '/complaint';
  return post(url, params);
};

